import React from 'react';
import {useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import axios from 'axios'



const SubscribeForm = () => {

    // form values
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [interest, setInterest] = useState(0);


    const options = [
        {value: 1, label: "For my business/company"},
        {value: 2, label: "For my team"},
        {value: 3, label: "For my college work group/team"},
        {value: 4, label: "For my friend group"},
        {value: 5, label: "Other"}
    ];

    const sendDataToServer =  (firstName, lastName, email) => {
        const apiUrl = '/api/signup';
        axios.post(apiUrl, {
              firstName : firstName,
              lastName : lastName,
              email_address : email,
              interest : interest
            }
          )
          .then(function (response) {
            if (response.status < 300 || (response.status === 400 && response.body.title === "Member Exists")) {
              console.log('Signed Up!');
            } else {
              console.log('Sign Up Failed :(');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }


    //form validation
    const [validated, setValidated] = useState(false);


    const handleSubmit = (event) => {
        console.log('=====> clicked event ===>');
        const form = event.currentTarget;
        if (form.checkValidity() === false || interest === "invalid") {
          event.preventDefault();
          event.stopPropagation();

        } else {
            sendDataToServer(firstName,lastName,email);
        }
        setValidated(true);


    }

    //form submission

    return(
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Row>
                <Form.Control placeholder = "First Name*" type="firstName" id = "firstName" value = {firstName} onChange={(e) => setFirstName(e.target.value)} required/>
            </Form.Row>
            <Form.Row className = "pt-3">
                <Form.Control placeholder = "Last Name*" type="lastName" id = "lastName" value = {lastName} onChange={(e) => setLastName(e.target.value)} required/>
            </Form.Row>
            <Form.Row className = "pt-3">
                <Form.Control placeholder = "Email*"
                type="email" id = "email" value = {email} onChange={(e) => setEmail(e.target.value)}
                required pattern ="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
            </Form.Row>
            <Form.Row className = "pl-1 pt-3">
                <Form.Group>
                    <Form.Label>What are you interested in Circal for?</Form.Label>
                    <Form.Control placeholder = "Interest"  id="interest" value = {interest} onChange={(e) => setInterest(e.target.value)} as="select" required>
                        <option value = "none">Choose...</option>
                        <option value = "business">For my entire business</option>
                        <option value = "team">For my entire team</option>
                        <option value = "college">For my college work group</option>
                        <option value = "friends">For my friend group</option>
                        <option value = "other">Other</option>
                    </Form.Control>
                </Form.Group>
            </Form.Row>
            <Button id = "cta" className = "custom-button" type= "submit" variant="primary">
                Submit
            </Button>
        </Form>
    );
};

export default SubscribeForm;
