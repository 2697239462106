import React from 'react';
import {useState} from 'react';
import {Button, Row, Col, Container, Form, Modal} from 'react-bootstrap';
import SubscribeForm from './SubscribeForm.js';


function SubscribeModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
        <Button className = "custom-button" onClick={handleShow}>subscribe</Button>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Subscribe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SubscribeForm/>
        </Modal.Body>
      </Modal>
      </>
    );
}

export default SubscribeModal;