import React from 'react';
import {Container, Image, Button, Row, Col, ResponsiveEmbed, Navbar} from 'react-bootstrap';
import SubscribeModal from './SubscribeModal.js';
import './App.css';

class Home extends React.Component {
  render() {
    return (
      <Container className = "min-vw-100 min-vh-100 w-100 h-100 pl-0 pr-0 mr-0" fluid>
         <Navbar variant="dark" className = "min-vw-100">
            <Navbar.Brand href="#home">
              <img
                src="/Logo.svg"
                width="200"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
          </Navbar>
          <Row lg = {12} className = "min-vh-100 pt-lg-0 mt-lg-n5">
            <Col lg = {5} className = "text pl-5 my-auto pb-5">
              <h1 style = {{fontWeight: '500'}}>Meetings made easy, like they should be.</h1>
              <p>Sign up for our VIP newsletter to recieve updates on our product development process, scheduling/producitivity hacks, and more!</p>
              <SubscribeModal/>
            </Col>
            <Col lg = {7} className = "mr-n5 my-auto pb-5 pr-0">
              <ResponsiveEmbed aspectRatio="16by9">
                <img
                src = "./HomeDashboardImg.svg"
                width = "50px"
                height = "50px"
                />
              </ResponsiveEmbed>
            </Col>
            <footer id="sticky-footer" class="mt-5 pt-5 text-white-50 min-vw-100">
              <div class="container text-center">
                <p>Designed with 🤍 by the Circal Dev Team</p>
                <p className = "mt-n3">Copyright &copy; www.circal.io</p>
                <a href = "mailto:circal.business@gmail.com">
                  <Button variant = "link" style = {{color: 'white', opacity: '70%'}} className = "mt-n5">
                      <small>Contact</small>
                  </Button>
                </a>
              </div>
            </footer>
          </Row>
      </Container>
    );
  }
}

export default Home;
